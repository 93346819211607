<template>
  <div class="image-upload">
    <el-upload
      v-loading="loading"
      class="image-uploader"
      action=""
      :show-file-list="false"
      :before-upload="beforeImageUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus image-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
const COS = require('cos-js-sdk-v5')
const Bucket = 'babysitter-1314465068'
const Region = 'ap-shanghai'

// 创建一个 COS SDK 实例
const cos = new COS({
  SecretId: 'AKIDFNKDuNbbV36vXyDD1cz94lCblnohMmzy',
  SecretKey: 'nOlt9E4hdLo59yYxFLDqPnxtb7PVO3Vk'
})

export default {
  name: 'ImageUpload',
  props: {
    value: {
      type: String,
      default: ''
    },
    dir: {
      type: String,
      default: 'files'
    },
    fileSize: {
      type: Number,
      default: 5
    },
    playBack: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      imageUrl: this.value,
      loading: false
    }
  },
  methods: {
    beforeImageUpload (file) {
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`)
          return false
        }
      }
      this.loading = true
      this.uploadFileToTencentClound(file).then((response) => {
        this.loading = false
        const url = `https://${response}`
        if (this.playBack) {
          this.imageUrl = url
        }
        this.$emit('input', url)
      })
    },
    uploadFileToTencentClound (file) {
      return new Promise((resolve) => {
        cos.putObject(
          {
            Bucket: Bucket /* 存储桶 */,
            Region: Region /* 存储桶所在地域，必须字段 */,
            Key: `${this.dir}/${Date.now()}.${file.name.split('.')[1]}` /* 文件名 */,
            StorageClass: 'STANDARD', // 上传模式, 标准模式
            Body: file, // 上传文件对象
            onProgress: function (info) {
              // console.log('[cos.postObject-seccess]', JSON.stringify(info))
            }
          },
          // eslint-disable-next-line handle-callback-err
          function (err, data) {
            // console.log('[cos.postObject-err]', err || data)
            resolve(data.Location)
          }
        )
      })
    }
  }
}
</script>

<style lang="scss">
.image-upload {
  width: 178px;
  height: 178px;
  line-height: 0;
  .image-uploader {
    width: 100%;
    height: 100%;
  }
  .image-uploader .el-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .image-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .image-uploader-icon {
    font-size: 28px;
    color: #8c939d;
  }
  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>
