<template>
  <div class="upload-file">
    <el-upload
      action="#"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :show-file-list="false"
      class="upload-file-uploader"
      ref="upload"
    >
      <!-- 上传按钮 -->
      <el-button type="primary">选取文件</el-button>
      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip" v-if="showTip">
        请上传
        <template v-if="fileSize">
          大小不超过
          <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileType">
          格式为
          <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
        </template>
        的文件
      </div>
    </el-upload>
    <!-- 文件列表 -->
    <ul class="upload-file-list el-upload-list el-upload-list--text">
      <template v-for="(file, index) in fileList">
        <li
          :key="file.uid"
          class="el-upload-list__item ele-upload-list__item-content"
        >
          <el-link :href="`${file.url}`" :underline="false" target="_blank">
            <span class="el-icon-document">{{ getFileName(file.url) }}</span>
          </el-link>
          <div class="ele-upload-list__item-content-action">
            <el-link
              v-if="showDelete"
              :underline="false"
              @click="handleDelete(index)"
              type="danger"
              >删除</el-link
            >
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
const COS = require('cos-js-sdk-v5')
const Bucket = 'babysitter-1314465068'
const Region = 'ap-shanghai'
// 创建一个 COS SDK 实例
const cos = new COS({
  SecretId: 'AKIDFNKDuNbbV36vXyDD1cz94lCblnohMmzy',
  SecretKey: 'nOlt9E4hdLo59yYxFLDqPnxtb7PVO3Vk'
})
export default {
  name: 'FileUpload',
  props: {
    // 值
    value: [String, Object, Array],
    // 数量限制
    limit: {
      type: Number,
      default: 5
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ['doc', 'xls', 'ppt', 'txt', 'pdf']
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      fileList: [],
      cndUrl: ''
    }
  },
  watch: {
    value: {
      handler (val) {
        if (val) {
          let temp = 1
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.value.split(',')
          // 然后将数组转为对象数组
          this.fileList = list.map((item) => {
            if (typeof item === 'string') {
              if (item.indexOf(this.cndUrl) === -1) {
                item = { name: item, url: this.cndUrl + item }
              } else {
                item = { name: item, url: item }
              }
            }
            item.uid = item.uid || new Date().getTime() + temp++
            return item
          })
        } else {
          this.fileList = []
          return []
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip () {
      return this.isShowTip && (this.fileType || this.fileSize)
    }
  },
  methods: {
    // 上传前校检格式和大小
    handleBeforeUpload (file) {
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = ''
        if (file.name.lastIndexOf('.') > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true
          if (fileExtension && fileExtension.indexOf(type) > -1) return true
          return false
        })
        if (!isTypeOk) {
          this.$message.error(
            `文件格式不正确, 请上传${this.fileType.join('/')}格式文件!`
          )
          return false
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`)
          return false
        }
      }
      this.uploadFileToTencentClound(file).then((response) => {
        this.fileList.push({ name: file.uid, url: 'https://' + response })
        this.$emit('input', this.listToString(this.fileList))
      })
    },
    /** 上传图片到腾讯云**/
    uploadFileToTencentClound (file) {
      return new Promise((resolve) => {
        cos.putObject(
          {
            Bucket: Bucket /* 存储桶 */,
            Region: Region /* 存储桶所在地域，必须字段 */,
            Key: 'files/' + file.name /* 文件名 */,
            StorageClass: 'STANDARD', // 上传模式, 标准模式
            Body: file, // 上传文件对象
            onProgress: function (info) {
              // console.log('[cos.postObject-seccess]', JSON.stringify(info))
            }
          },
          // eslint-disable-next-line handle-callback-err
          function (err, data) {
            // console.log('[cos.postObject-err]', err || data)
            resolve(data.Location)
          }
        )
      })
    },
    // 删除文件
    handleDelete (index) {
      this.fileList.splice(index, 1)
      this.$emit('input', this.listToString(this.fileList))
    },
    // 获取文件名称
    getFileName (name) {
      if (name.lastIndexOf('/') > -1) {
        return name.slice(name.lastIndexOf('/') + 1).toLowerCase()
      } else {
        return ''
      }
    },
    // 对象转成指定字符串分隔
    listToString (list, separator) {
      let strs = ''
      separator = separator || ','
      for (const i in list) {
        strs += list[i].url + separator
      }
      return strs !== '' ? strs.substr(0, strs.length - 1) : ''
    }
  }
}
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
</style>
