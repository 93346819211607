import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './styles/index.scss'
// 文件上传
import FileUpload from '@/components/FileUpload'
// 图片上传
import ImageUpload from '@/components/ImageUpload'

Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)

Vue.use(ElementUI)

Vue.filter('cutStr', (value, number) => {
  let str = value
  if (value && value.length > number) {
    str = `${value.substring(0, number)}...`
  }
  return str
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
