import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "Layout" */ '../views/layout/index'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Home',
        meta: {
          title: '首页'
        },
        component: () => import(/* webpackChunkName: "Home" */ '../views/home/index')
      },
      {
        path: '/category',
        name: 'Category',
        meta: {
          title: '栏目管理'
        },
        component: () => import(/* webpackChunkName: "Category" */ '../views/category/index')
      },
      {
        path: '/article',
        name: 'Article',
        meta: {
          title: '文章管理'
        },
        component: () => import(/* webpackChunkName: "Article" */ '../views/article/index')
      },
      {
        path: '/babysitter',
        name: 'Babysitter',
        meta: {
          title: '月嫂管理'
        },
        component: () => import(/* webpackChunkName: "Babysitter" */ '../views/babysitter/index')
      },
      {
        path: '/product',
        name: 'Product',
        meta: {
          title: '商品管理'
        },
        component: () => import(/* webpackChunkName: "Product" */ '../views/product/index')
      },
      {
        path: '/appointment',
        name: 'Appointment',
        meta: {
          title: '预约管理'
        },
        component: () => import(/* webpackChunkName: "Appointment" */ '../views/appointment/index')
      },
      {
        path: '/user',
        name: 'User',
        meta: {
          title: '用户管理'
        },
        component: () => import(/* webpackChunkName: "User" */ '../views/user/index')
      },
      {
        path: '/question',
        name: 'Question',
        meta: {
          title: '常见问题'
        },
        component: () => import(/* webpackChunkName: "Question" */ '../views/question/index')
      },
      {
        path: '/setting',
        name: 'Setting',
        meta: {
          title: '系统管理'
        },
        component: () => import(/* webpackChunkName: "Setting" */ '../views/setting/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/login/index')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user) {
      return next({
        name: 'Login',
        query: {
          redirect: to.fullPath
        }
      })
    }
    next()
  } else {
    next()
  }
})

export default router
